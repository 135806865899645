import React from 'react';
import styled from 'styled-components';
import media from '../../styles/breakpoints';
import { typeSize } from '../../styles/utils';

const offset = '5px';
const borderSize = '2px';
const horizontalOffset = `calc(${offset} * -1)`;
const verticalOffset = `calc(${offset} * -1)`;

const ButtonBorderCommon = styled.div`
  position: absolute;
  transition: transform 0.8s ease;
  will-change: transform;

  &::before {
    content: '';
    position: absolute;
    border: inherit;
  }
`;

const ButtonHorizontal = styled(ButtonBorderCommon)`
  top: 0;
  bottom: 0;
  right: ${verticalOffset};
  left: ${verticalOffset};
  border-top: ${borderSize} solid currentcolor;
  border-bottom: ${borderSize} solid currentcolor;

  &::before {
    top: calc(${verticalOffset} - ${borderSize});
    bottom: calc(${verticalOffset} - ${borderSize});
    left: calc(${verticalOffset} * -1);
    right: calc(${verticalOffset} * -1);
  }
`;

const ButtonVertical = styled(ButtonBorderCommon)`
  right: 0;
  left: 0;
  top: ${horizontalOffset};
  bottom: ${horizontalOffset};
  border-left: ${borderSize} solid currentcolor;
  border-right: ${borderSize} solid currentcolor;

  &::before {
    top: calc(${horizontalOffset} * -1);
    bottom: calc(${horizontalOffset} * -1);
    left: calc(${horizontalOffset} - ${borderSize});
    right: calc(${horizontalOffset} - ${borderSize});
  }
`;

const ButtonStyle = styled.button`
  font-size: ${typeSize(-1)};
  display: block;
  position: relative;
  padding: 16px 32px;
  appearance: none;
  border: 0;
  background: transparent;
  color: ${props => props.theme.colors.yellow};
  text-transform: uppercase;
  letter-spacing: 0.25em;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 0;
  box-shadow: inset 0 0 0 ${borderSize} currentcolor;
  transition: background 1s ease, color 0.8s ease;
  text-transform: uppercase;
  z-index: 10;

  &:hover {
    background: rgba(100, 0, 0, 0.03);
    background-color: ${props => props.theme.colors.yellow};
    color: ${props => props.theme.colors.offBlack};

    ${ButtonVertical} {
      color: ${props => props.theme.colors.yellow};
      transform: scaleY(0);
    }

    ${ButtonHorizontal} {
      color: ${props => props.theme.colors.yellow};
      transform: scaleX(0);
    }
  }

  ${media.lessThan('sm')`
    font-size: ${typeSize(-2)};
  `}
`;

const Button = props => (
  <ButtonStyle type="button">
    {props.insideText}
    <ButtonHorizontal />
    <ButtonVertical />
  </ButtonStyle>
);

export default Button;
