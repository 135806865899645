import React from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';
import logo from '../images/logo.svg';
import { H3, P, A } from '../components/Type';
import Button from '../components/Button';
import media from '../../styles/breakpoints';
import { typeSize } from '../../styles/utils';

const Logo = styled.img`
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;

  ${media.greaterThan('sm')`
    max-width: none;
    width: 520px;
  `}

  ${media.greaterThan('md')`
    width: 650px;
  `}

  ${media.greaterThan('lg')`
    width: 850px;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1;

  p,
  a {
    color: ${props => props.theme.colors.gray10};
  }
  p {
    margin-bottom: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 80px);

  ${media.lessThan('sm')`
    padding-left: 24px;
    padding-right: 24px;
    height: calc(100% - 72px);
  `}
`;

const SubText = styled(H3)`
  text-align: center;
  margin-top: 16px;

  ${media.lessThan('sm')`
    font-size: ${typeSize(-1)};
    letter-spacing: .2px;
  `}

  ${media.lessThan('xs')`
    font-size: ${typeSize(-2)};
    letter-spacing: .2px;
  `}
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2 1;

  ${media.lessThan('sm')`
    justify-content: start;
    margin-top: 80px;
  `}
`;

const IndexPage = () => (
  <>
    <SEO title="Home" keywords={[`San Diego`, `Experience`, `Dope`, `Folk`]} />
    <Wrapper>
      <Hero>
        <Logo src={logo} alt="dope folk" />
        <SubText>Your community should be dope</SubText>
      </Hero>
      <ButtonWrapper>
        <P>
          Next Experience: <A href="/">Coming Soon</A>
        </P>
        <Button insideText="Request Invite" />
      </ButtonWrapper>
    </Wrapper>
  </>
);

export default IndexPage;
